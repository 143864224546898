import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Stack, SvgIcon, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';

import { CmsModels, useGetCmsEntry, WelcomePageDetails } from 'api/cms';
import { useReportWelcomeNextClicked } from 'api/visit';
import { STORAGE_KEYS } from 'common/constants';
import useAuthenticationContext from 'common/contexts/AuthenticationContext';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import useClearSessionStorage from 'common/hooks/useClearSessionStorage';
import useGetVisitFromQueryParam from 'common/hooks/useGetPatientVisit';
import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import { useClearLoggerContext } from 'common/hooks/useLoggerContext';
import { useSessionStorage } from 'common/hooks/useStorage';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { useUrlSearchParams } from 'common/hooks/useUrlSearchParams';
import { QueryParams } from 'common/models/queryParams';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import { setIsVisibleObserver } from 'common/utils';
import ErrorDialog from 'components/common/ErrorDialog';
import LanguagePicker from 'components/common/LanguagePicker';
import Loader from 'components/common/Loader';
import QuestionnairePicker from 'components/common/QuestionnairePicker';
import TransWithGender from 'components/common/TransWithGender';
import LoginDrawer from 'components/login/LoginDrawer';
import LoginPage from 'components/login/LoginPage';
import { RoutesEnum } from 'components/router/Routes';
import TermsAndConditions, { TermsAndConditionsRef } from './TermsAndConditions';

const WelcomePage: React.FC = () => {
	useClearSessionStorage();
	useClearLoggerContext();
	const { searchParams } = useUrlSearchParams();
	const navigate = useNavigate();
	const { t, i18n } = useTranslationWithGender();
	const featureFlags = useFeatureFlags();
	const userDetails = useAuthenticationContext();

	const {
		isNewVisit,
		isLoading,
		isError: isGetPatientVisitError,
		isVisitQueryParamExists,
		isGetVisitRequestSuccess,
		visitIdParam
	} = useGetVisitFromQueryParam();

	const reportWelcomeNextClicked = useReportWelcomeNextClicked();
	const [nextButtonObserver, setNextButtonObserver] = useState<IntersectionObserver | null>(null);

	const nextButtonRef = useCallback((nextButtonNode) => {
		if (!nextButtonNode || nextButtonObserver) return;
		const handleVisible = () => {
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Welcome.NextVisible);
			LoggerService.info('Next button is now visible');
		};
		const handleInvisible = () => {
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Welcome.NextInvisible);
			LoggerService.info('Next button is not visible yet');
		};
		const observer = setIsVisibleObserver(nextButtonNode, handleVisible, handleInvisible);
		setNextButtonObserver(observer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return () => {
			nextButtonObserver?.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const theme = useTheme();
	const [patientId] = useSessionStorage<string>(STORAGE_KEYS.PATIENT_ID);

	const environment = featureFlags?.translationNamespace as string;
	const { data: welcomePageDetails, isError: isWelcomePageDetailsError } = useGetCmsEntry<WelcomePageDetails>(
		CmsModels.WelcomePage,
		i18n.language,
		environment
	);

	const isMdOrUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
	const [loginDrawerOpen, setLoginDrawerOpen] = useState(false);
	const isSymptomSelected = searchParams.get(QueryParams.SymptomSelected);

	const isLoginEnabled = featureFlags?.isLoginEnabled && !userDetails.isAuthenticated;

	useInitializeAnalytics(AnalyticsService.EVENTS.Welcome.Initialized);

	const explanationText = t('welcomePage.explanation');

	const [isTermsOfUseToggled, setIsTermsOfUseToggled] = useState(false);
	const showTermsAndConditions = !!featureFlags?.showTermsAndConditions;
	const termsAndConditionsComponentRef = React.useRef<TermsAndConditionsRef>(null);

	const handleMainButtonClick = async () => {
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Welcome.NextClicked);
		if (visitIdParam) {
			reportWelcomeNextClicked.mutate(visitIdParam);
		}

		if (isTermsOfUseToggled && termsAndConditionsComponentRef.current) {
			termsAndConditionsComponentRef.current.createConsents();
		}

		if (isLoginEnabled) {
			setLoginDrawerOpen(true);
		} else {
			navigateToNextPage();
		}
	};

	const navigateToNextPage = async () => {
		if (userDetails.isLoading) return;
		await userDetails.refresh();
		if (isVisitQueryParamExists) {
			if (!isLoading) {
				isNewVisit ? navigate(RoutesEnum.Symptom) : navigate(RoutesEnum.Questionnaire);
			}
		} else if (featureFlags?.hasEligibilityPage) {
			navigate(RoutesEnum.Eligibility);
		} else {
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.BlockEligibilityPageError.Initialized);
			navigate(RoutesEnum.Forbidden);
		}
	};

	const shouldShowLoginSection = isMdOrUp && isLoginEnabled;

	useEffect(() => {
		if (isGetVisitRequestSuccess && isSymptomSelected) {
			navigate(RoutesEnum.Questionnaire);
		}
	}, [isGetVisitRequestSuccess, isSymptomSelected, navigate]);

	const showQuestionnairePicker = !!featureFlags?.questionnaireSelection;
	const showLanguagePicker = (featureFlags?.languages?.length || 0) > 1;
	const isShowingPartnerLogo = useMediaQuery<Theme>((theme) => theme.breakpoints.down(theme.extraThemeOptions.showPartnerLogoWidthThreshold));
	const showHeaderSection = isShowingPartnerLogo || showQuestionnairePicker || showLanguagePicker;

	const welcomeSection = (
		<Grid height="100%" container direction="column" justifyContent="space-between">
			{showHeaderSection && (
				<Grid item container justifyContent="space-between" height={60}>
					{isShowingPartnerLogo && <Box component="img" src={welcomePageDetails?.partner_logo} alt="" maxWidth={120} maxHeight={60} />}
					{showQuestionnairePicker && <QuestionnairePicker />}
					{showLanguagePicker && <LanguagePicker />}
				</Grid>
			)}

			<Grid
				item
				position="relative"
				container
				flexDirection="column"
				alignItems={{ xs: 'center', md: shouldShowLoginSection ? 'center' : 'start' }}
				textAlign={{ xl: shouldShowLoginSection ? 'center' : 'start' }}
				height={{ xs: theme.extraThemeOptions.showButtonAtBottomOfThePage ? '90%' : 'auto' }}
				mt={4}
			>
				<Typography
					mb={2}
					variant="h1"
					fontSize={{ sm: 48 }}
					lineHeight={{ sm: 54 / 48 }}
					maxWidth={560}
					alignItems="start"
					sx={{
						width: '100%'
					}}
				>
					<TransWithGender
						i18nKey="welcomePage.title"
						components={{
							highlighted: <Box component="span" color={(theme) => theme.palette.secondary.main} />
						}}
					/>
				</Typography>
				{theme.extraThemeOptions.showBrightLayout ? (
					<Typography maxWidth={560} mb={2} variant="h3" color={(theme) => theme.palette.text.primary} whiteSpace="break-spaces">
						<TransWithGender
							i18nKey="welcomePage.body"
							components={{
								highlighted: <Box component="span" fontSize={22} fontWeight={700} color={(theme) => theme.palette.text.primary} />
							}}
						/>
					</Typography>
				) : (
					<Typography
						maxWidth={560}
						mb={3}
						display="block"
						variant="body3"
						fontSize={{ sm: 20 }}
						lineHeight={{ sm: 28 / 20 }}
						whiteSpace="break-spaces"
					>
						<TransWithGender
							i18nKey="welcomePage.body"
							components={{
								highlighted: (
									<Box component="span" fontSize={{ sm: 20 }} fontWeight={700} color={(theme) => theme.palette.text.white} />
								)
							}}
						/>
					</Typography>
				)}
				{explanationText && (
					<Typography maxWidth={560} mb={3} display="block" variant="caption" whiteSpace="break-spaces">
						{explanationText}
					</Typography>
				)}
				{!shouldShowLoginSection && (
					<Stack
						sx={{
							width: '100%',
							maxWidth: { xs: '327px', md: 'unset' },
							alignItems: { xs: 'center', md: 'start' },
							marginTop: theme.extraThemeOptions.showButtonAtBottomOfThePage ? 'auto' : '0'
						}}
					>
						{showTermsAndConditions && (
							<TermsAndConditions
								ref={termsAndConditionsComponentRef}
								patientId={userDetails?.patientId || patientId}
								onTermsOfUseToggled={setIsTermsOfUseToggled}
							/>
						)}
						<Button
							sx={{
								width: { xs: '100%', md: 'fit-content' },
								zIndex: 1
							}}
							variant="contained"
							color={theme.extraThemeOptions.buttonColor}
							onClick={handleMainButtonClick}
							data-testid="continue"
							disabled={showTermsAndConditions && !isTermsOfUseToggled}
							ref={nextButtonRef}
						>
							{t('welcomePage.button')}
						</Button>
					</Stack>
				)}
			</Grid>

			<Grid
				item
				container
				direction="column"
				alignItems={{
					xs: 'center',
					md: shouldShowLoginSection ? 'center' : theme.direction === 'rtl' ? 'flex-end' : 'flex-start'
				}}
				mt={{
					xs: theme.extraThemeOptions.showButtonAtBottomOfThePage ? 0 : 'auto',
					md: theme.extraThemeOptions.showButtonAtBottomOfThePage ? 0 : 6
				}}
			>
				<Typography
					mb={1}
					mt={{ xs: theme.extraThemeOptions.showButtonAtBottomOfThePage ? 0 : 14, md: 0 }}
					variant="body4"
					fontSize={10}
					color={(theme) => (theme.extraThemeOptions.showBrightLayout ? theme.palette.text.primary : undefined)}
				>
					{t('welcomePage.poweredBy')}
				</Typography>
				<SvgIcon sx={{ width: 121, height: 20 }} component={theme.extraThemeOptions.companyLogo} inheritViewBox />
			</Grid>
		</Grid>
	);

	if (isSymptomSelected || (isLoading && isVisitQueryParamExists)) {
		return <Loader />;
	}

	const background = theme.extraThemeOptions.background;

	return (
		<Grid height="100%" overflow="hidden auto">
			<ErrorDialog errorConditions={[isWelcomePageDetailsError, isGetPatientVisitError]} />
			{shouldShowLoginSection ? (
				<Grid container minHeight="100%">
					<Grid
						item
						xs={7}
						py={3}
						px={{ xs: 3, lg: 9 }}
						sx={(theme) => ({
							background,
							color: theme.palette.common.white
						})}
					>
						{welcomeSection}
					</Grid>
					<Grid item xs={5}>
						<LoginPage onLoginSuccess={navigateToNextPage} />
					</Grid>
				</Grid>
			) : (
				<Grid
					container
					sx={(theme) => ({
						background,
						color: theme.palette.common.white,
						minHeight: '100%'
					})}
				>
					<Container sx={{ flex: 1 }} maxWidth="lg">
						{welcomeSection}
						<LoginDrawer open={loginDrawerOpen} onClose={() => setLoginDrawerOpen(false)} onLoginSuccess={navigateToNextPage} />
					</Container>
				</Grid>
			)}
		</Grid>
	);
};

export default WelcomePage;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { animated, useTrail } from 'react-spring';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { Sex, Symptom, useGetSymptomSearchAutocomplete, useGetSymptomSearchResults, useSelectSymptom } from 'api/symptom';
import { useReportSymptomSearched } from 'api/visit';
import { QUESTIONS_SPRING_CONFIG, STORAGE_KEYS, SYMPTOM_SEARCH_DEBOUNCE_IN_MS } from 'common/constants';
import useAuthenticationContext from 'common/contexts/AuthenticationContext';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import useDebounce from 'common/hooks/useDebounce';
import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import ErrorDialog from 'components/common/ErrorDialog';
import { RoutesEnum } from 'components/router/Routes';
import CommonSymptoms from 'components/symptom/CommonSymptoms';
import NoResults from 'components/symptom/NoResults';
import QueryResults from 'components/symptom/QueryResults';
import SymptomAutocompleteSmallScreen from 'components/symptom/SymptomAutocompleteSmallScreen';
import SymptomAutocomplete from './SymptomAutocomplete';

const SymptomPage: React.FC = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const featureFlags = useFeatureFlags();
	const { t } = useTranslationWithGender();
	const isSmallScreenOrDown = useMediaQuery(theme.breakpoints.down('md'));
	const [inAnimations] = useTrail(3, () => ({ ...QUESTIONS_SPRING_CONFIG }));

	const visitId = sessionStorage.getItem(STORAGE_KEYS.VISIT_ID) as string;
	const sex = sessionStorage.getItem(STORAGE_KEYS.SEX) as Sex;

	const [query, setQuery] = useState<string | null>(null);
	const [searchTerm, setSearchTerm] = useState<string | null>(null);
	const [shouldShowAutocompleteSmallScreen, setShouldShowAutocompleteSmallScreen] = useState<boolean>(false);
	const [isCommonSymptomsError, setIsCommonSymptomsError] = useState<boolean>(false);
	const debouncedSearchTerm = useDebounce(searchTerm, SYMPTOM_SEARCH_DEBOUNCE_IN_MS);

	const userDetails = useAuthenticationContext();

	useEffect(() => {
		if (!userDetails?.token) LoggerService.warning("User's token is not available");
		else LoggerService.info('User token is available');
	}, [userDetails?.token]);

	const {
		data: searchResults,
		refetch: refetchSearchResults,
		isError: isSearchResultsError
	} = useGetSymptomSearchResults(visitId, searchTerm!, sex);

	const { data: searchAutocompleteData } = useGetSymptomSearchAutocomplete(debouncedSearchTerm || '', sex);
	const selectSymptomMutation = useSelectSymptom(visitId);
	const reportSymptomSearchedMutation = useReportSymptomSearched();

	useInitializeAnalytics(AnalyticsService.EVENTS.Symptom.Initialized);

	useEffect(() => {
		if (debouncedSearchTerm) {
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Symptom.Searched);
			if (visitId) {
				reportSymptomSearchedMutation.mutate({ visitId: visitId, searchTerm: debouncedSearchTerm });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (query) {
			refetchSearchResults();
		}
	}, [query, refetchSearchResults]);

	const handleSearchTermChange = (value: string | null) => {
		LoggerService.info(`SymptomPage: handleSearchTermChange: ${value}`);
		setSearchTerm(value);
	};

	const handleSymptomAutocompleteFocus = () => {
		if (isSmallScreenOrDown) {
			setShouldShowAutocompleteSmallScreen(true);
		}
	};

	const handleBackClick = () => {
		setSearchTerm(null);
		setShouldShowAutocompleteSmallScreen(false);
	};

	const handleClearClick = () => {
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Symptom.Clear);
		if (isSmallScreenOrDown) {
			setSearchTerm(null);
			setShouldShowAutocompleteSmallScreen(true);
		}
	};

	const handleResultClick = (symptom: string, isCommonSymptomClick: boolean = false) => {
		if (isCommonSymptomClick) {
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Symptom.CommonSymptomSelected);
		} else {
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Symptom.Searched);
		}

		setShouldShowAutocompleteSmallScreen(false);
		setSearchTerm(symptom);
		setQuery(symptom);
	};

	const handleClickSymptom = async (symptom: Symptom) => {
		LoggerService.info(`user selected symptom (${symptom.title})`);
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Symptom.Selected);
		await selectSymptomMutation.mutateAsync(symptom.id);
		LoggerService.info(`Selected symptom (${symptom.title}) has been submitted successfully`);
		if (featureFlags?.hasPreAnsweredQuestionsPage) {
			navigate(RoutesEnum.PreAnsweredQuestions);
		} else {
			navigate(RoutesEnum.Questionnaire);
		}
	};

	const onErrorDialogClose = () => {
		setIsCommonSymptomsError(false);
		selectSymptomMutation.reset();
	};

	return (
		<>
			<ErrorDialog errorConditions={[selectSymptomMutation.isError, isCommonSymptomsError, !sex]} onClose={onErrorDialogClose} />

			{shouldShowAutocompleteSmallScreen ? (
				<SymptomAutocompleteSmallScreen
					value={searchTerm}
					placeholder={t('symptomSelection.searchTypingPlaceholder')}
					handleOnBackClick={handleBackClick}
					onChange={handleSearchTermChange}
					onClear={handleClearClick}
					handleAutocompleteResultClick={handleResultClick}
					searchAutocompleteData={searchAutocompleteData || []}
				/>
			) : (
				<Grid container spacing={3} flexDirection="column">
					<Grid item sx={{ whiteSpace: 'pre-line' }}>
						<animated.div style={inAnimations[0]}>
							<Typography typography={{ xs: 'h3', sm: 'h2' }}>{t('symptomSelection.title')}</Typography>
						</animated.div>
					</Grid>

					<Grid item>
						<animated.div style={inAnimations[1]}>
							<SymptomAutocomplete
								searchTerm={searchTerm}
								onChange={handleSearchTermChange}
								onClick={(symptom) => handleResultClick(symptom, false)}
								onClear={handleClearClick}
								handleFocus={handleSymptomAutocompleteFocus}
								placeholder={t('symptomSelection.searchPlaceholder')}
								searchAutocompleteData={searchAutocompleteData || []}
							/>
						</animated.div>
					</Grid>

					{query && (!!searchResults || isSearchResultsError) ? (
						<Grid item>
							<animated.div style={inAnimations[2]}>
								{searchResults && searchResults?.length > 0 ? (
									<QueryResults query={query} results={searchResults} onClick={handleClickSymptom} />
								) : isSearchResultsError || searchResults?.length === 0 ? (
									<NoResults
										visitId={visitId}
										query={query}
										title={t('symptomSelection.noResultsTitle')}
										body={t('symptomSelection.noResultsBody', { returnObjects: true })}
										allSymptomsLink="symptomSelection.allSymptomsLink"
										allSymptomsTitle={t('symptomSelection.allSymptomsTitle')}
										allSymptomsSubtitle={t('symptomSelection.allSymptomsSubtitle')}
										onClick={handleClickSymptom}
									/>
								) : null}
							</animated.div>
						</Grid>
					) : (
						<Grid item>
							<animated.div style={inAnimations[2]}>
								<CommonSymptoms onClick={(symptom) => handleResultClick(symptom, true)} setIsError={setIsCommonSymptomsError} />
							</animated.div>
						</Grid>
					)}
				</Grid>
			)}
		</>
	);
};

export default SymptomPage;
